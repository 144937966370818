import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import grid from "../../scss/flexboxgrid.module.scss"
import view from "./searchbar.module.scss"
import DropdownOption from "./Dropdown"
import RadioOption from "./RadioOption"

const staticQuery = (graphql`
  query {
    LunrIndex
    allInventoryJson {
      nodes {
        DealerID
        VehicleInfo {
          IsCertified
          IsNew
          Model
          Make
          BodyType
          VehicleStatus
          Year
          VIN
          DealerID
          DealerName
        }
        Banner {
          BannerContent {
          bgColor
          placement
          text
          textColor
          }
        }
      }
    }
  }
`)

export default function SearchBar({ data, mainDealerID }) {
  const dealerID = mainDealerID
  let dealerNameNoSpace = "";
  const labelMap = {
    certified: { Certified: true },
    new: { New: true, 'Pre-Owned': false, Used: false }
  }
  const [filters, setFilters] = useState({
   certified: "", new: "", make: "Aston Martin", model: "", year: "", search: ""
  })
  const [criteria, setCriteria] = useState({
   certified: {}, new: {}, make: "Aston Martin", model: {}, year: {}
  })
  const { allInventoryJson } = useStaticQuery(staticQuery)
  const allInventoryJsonClean = removeDups(allInventoryJson.nodes)
  const includeCertified = data.includeCertified
  
  useEffect(() => {
    const _criteria = countUnique(allInventoryJson.nodes)
    setCriteria(_criteria)
  }, [allInventoryJson.nodes])

  const filtersMap = {
    "certified": (node, value) => node.VehicleInfo.IsCertified === value,
    "new": (node, value) => node.VehicleInfo.IsNew === value,
    "make": (node, value) => node.VehicleInfo.Make === value,
    "model": (node, value) => node.VehicleInfo.Model === value,
    "year": (node, value) => node.VehicleInfo.Year === Number(value),
  }

  function filterVehiclesByDealerID(vehicles, dealerID) {
    if (dealerID === "") {
      return vehicles;
    }
    const intDealerID = parseInt(dealerID, 10);
    const filteredVehicles = vehicles.filter(node => {
      if (node.VehicleInfo.DealerID === intDealerID) {
        const sortDealerName = node.VehicleInfo.DealerName;
        dealerNameNoSpace = sortDealerName.replace(/\s+/g, '');
        return true;
      }
      return false;
    });
    return filteredVehicles;
  }  

  function countUnique(vehicles) {
    vehicles = filterVehiclesByDealerID(vehicles, dealerID);
    const discardMap = {
      certified: {
        Certified: vehicles.filter(node => node.VehicleInfo.IsCertified === true && node.VehicleInfo.VehicleStatus !== "_hidden").length
      },
      new: {
        New: vehicles.filter(node => node.VehicleInfo.IsNew === true && node.VehicleInfo.VehicleStatus !== "_hidden").length,
        'Pre-Owned': vehicles.filter(node => node.VehicleInfo.IsNew === false && node.VehicleInfo.VehicleStatus !== "_hidden").length,
      },
      model: {},
      make: "Aston Martin",
      year: {}
    }

    vehicles.forEach(node => {
      if (node.VehicleInfo.VehicleStatus != "_hidden") {
        if (discardMap["year"].hasOwnProperty(node.VehicleInfo.Year)) {
          discardMap["year"][node.VehicleInfo.Year]++
        } else {
          discardMap["year"][node.VehicleInfo.Year] = 1
        }

        if (discardMap["model"].hasOwnProperty(node.VehicleInfo.Model)) {
          discardMap["model"][node.VehicleInfo.Model]++
        } else {
          discardMap["model"][node.VehicleInfo.Model] = 1
        }

      }
    })

    return discardMap
  }

  const dropValues = {
    New: allInventoryJsonClean.filter(node => node.VehicleInfo.IsNew === true && node.VehicleInfo.VehicleStatus !== "_hidden").length,
    'Pre-Owned': allInventoryJsonClean.filter(node => node.VehicleInfo.IsNew === false && node.VehicleInfo.VehicleStatus !== "_hidden").length
  }
  
  const dropValues2 = {
    New: allInventoryJsonClean.filter(node => node.VehicleInfo.IsNew === true && node.VehicleInfo.VehicleStatus !== "_hidden").length,
    Used: allInventoryJsonClean.filter(node => (node.VehicleInfo.IsNew === false) && (node.VehicleInfo.IsCertified === false) && node.VehicleInfo.VehicleStatus !== "_hidden").length,
    Certified: allInventoryJsonClean.filter(node => node.VehicleInfo.IsCertified === true && node.VehicleInfo.VehicleStatus !== "_hidden" ).length
  }

  function removeDups(vehicles) {
    vehicles = filterVehiclesByDealerID(vehicles, dealerID);
    let unique = {};
    vehicles.forEach((v) => {
      if(!unique[v.VehicleInfo.VIN]) {
        unique[v.VehicleInfo.VIN] = v;
      }
    });
    let finalVehicleInfo = []
    Object.keys(unique).map((item, i) => (
        finalVehicleInfo = finalVehicleInfo.concat(unique[item])
    ))
    return finalVehicleInfo;
  }

  function matchCriteria() {
    let matched = allInventoryJsonClean
    Object.keys(filters).forEach(type => {
      if (filters[type] !== undefined && String(filters[type]).length > 1) {
        const array = matched
        matched = array.filter(node => filtersMap[type](node, filters[type]))
      }
    })

    return countUnique(matched)
  }

  const addFilter = ({ type, value }) => {
    if (value === 'Certified') {
      type = 'certified'
    }
    const _filters = filters
    _filters[type] = labelMap.hasOwnProperty(type) ? labelMap[type][value] : value
    
    setFilters({ ...filters, ..._filters })

    console.log(type, value)
    const _criteria = matchCriteria()
    if (value !== undefined && value.length > 1) {
      delete _criteria[type]
    }
    setCriteria({ ...criteria, ..._criteria })
  }

  const buildSearchQuery = (dealerNameNoSpace) => {
    const _filters = { ...filters };
  
    if (_filters.new === "") {
      _filters.new = true;
    }
  
    if (_filters.new === false) {
      _filters.certified = _filters.certified || true;
    }

    const makeForUrl = _filters.make ? _filters.make.replace(/\s+/g, '') : '';
  
    const queryFromFilters = Object.keys(_filters).map(key => {
      if (_filters[key] !== undefined) {
        const value = key === 'make' ? makeForUrl : _filters[key];
        return String(value).length > 1 ? escape(key) + '=' + escape(String(value)) : null;
      }
    }).filter(element => element != null).join('&');
  
    const dealerQuery = dealerNameNoSpace && dealerNameNoSpace !== "" ? `dealer=${dealerNameNoSpace}` : "";
    return [queryFromFilters, dealerQuery].filter(Boolean).join('&');
  }  

  const addSearchFilter = (event) => {
    const _filters = filters
    _filters.search = event.target.value
    setFilters({ ...filters, ..._filters })
  }

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      const query = `/search/?${buildSearchQuery(dealerNameNoSpace)}`
      window.open(query, "_self")
    }
  }

  const renderSearch = () => (
    <div className={view["filter-nsect"]}>
      <label for="searchbox" style={{display: "none"}}>Search Vehicles</label>
      <input id="searchbox" placeholder={"Type here to search"}
        onChange={addSearchFilter} onKeyDown={keyPress} />
    </div>
  )

  return (
    <>
      {/* responsive filtering */}
      {/*<div className={`${grid["col-xs-6"]} ${grid["col-sm-3"]} ${grid["col-lg"]} 
        ${view["camper-filter"]}`}>
        <div className={`${view["camper-item"]}`}>
          <div className={view["camper-cont"]}>
            <p>SHOW FILTERS</p>
          </div>
          <div className={`${view["camper-dcont"]}`}>
            <p className={view["camper-desc"]}>
              SHOW ITEMS
            </p>
          </div>
        </div>
      </div>
      /*}

      {/* non-responsive filtering */}
      <div className={`${grid["col-xs-12"]} ${view["search-bar"]}`}
        style={{ backgroundColor: data.bgOverride && data.bgColor }}>
        <div className={`${view["filter-nresp"]}`}>
          <div className={view["fullWidthSearch"]}>
            <h3 className={view["mobileSearchHeader"]}>Search Aston Martin cars</h3>
          </div>
          <div>
            <div className={view["radioSearch"]}>
              <RadioOption type="new" defaultValue="New"
                values={dropValues} addFilter={addFilter} />
            </div>
            <div className={view["searchbarDropdown"]}>
            <DropdownOption 
              type="model" 
              placeholder="ALL MODELS"
              values={Object.fromEntries(Object.entries(criteria.model).filter(([model, count]) => {
                // Calculate the count based on whether 'New' or 'Pre-Owned' is selected
                const vehicleCount = filters.new !== false
                  ? allInventoryJsonClean.filter(vehicle => 
                      vehicle.VehicleInfo.IsNew === true &&
                      vehicle.VehicleInfo.Make === "Aston Martin" &&
                      vehicle.VehicleInfo.Model === model
                    ).length
                  : allInventoryJsonClean.filter(vehicle => 
                      vehicle.VehicleInfo.IsNew === false &&
                      vehicle.VehicleInfo.Make === "Aston Martin" &&
                      vehicle.VehicleInfo.Model === model
                    ).length;

                // Only show models that have vehicles based on the selected filter (New or Pre-Owned)
                return vehicleCount > 0;
              }).map(([model, count]) => {
                // Ensure the tally reflects the correct count based on the filter (New or Pre-Owned)
                const vehicleCount = filters.new !== false
                  ? allInventoryJsonClean.filter(vehicle => 
                      vehicle.VehicleInfo.IsNew === true &&
                      vehicle.VehicleInfo.Make === "Aston Martin" &&
                      vehicle.VehicleInfo.Model === model
                    ).length
                  : allInventoryJsonClean.filter(vehicle => 
                      vehicle.VehicleInfo.IsNew === false &&
                      vehicle.VehicleInfo.Make === "Aston Martin" &&
                      vehicle.VehicleInfo.Model === model
                    ).length;

                return [model, vehicleCount];
              }))} 
              addFilter={addFilter} 
              label={"Model"}
              sorter={(a, b) => a.localeCompare(b)} 
            />
            </div>
            <div className={view["fullWidthSearch"]}>
              <Link className={view["filter-nbutn"]}
                style={{ backgroundColor: data.btOverride && data.btColor }}
                to={"/search/?" + buildSearchQuery(dealerNameNoSpace)}>
                Search
              </Link>
            </div>
        </div>
      </div>
    </div>
    </>
  )
}

SearchBar.defaultProps = {
  data: {
    bgOverride: true,
    bgColor: "#000000",
    btOverride: false,
    btcolor: "#ffffff",
    order: 4,
    visible: true
  },
  mainDealerID: ""
} 