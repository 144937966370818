import React from 'react';

const CustomDots = ({ currentSlide, totalSlides, goToSlide }) => {
    const dots = [];

    for (let i = 0; i < totalSlides; i++) {
        dots.push(
            <div
                key={i}
                className={`custom-dot ${i === currentSlide ? 'active' : ''}`}
                onClick={() => goToSlide(i)}
            />
        );
    }

    return (
        <div className="custom-dots-container">
            {dots}
        </div>
    );
};

export default CustomDots;