import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import Slider from "react-slick";
import './VehicleSlider.scss'


const VehicleSlider = (props) => {
    const { data } = props
    const { vehicleslider } = data
    const textSlider = React.useRef(null);
    const [textIndex, setTextIndex] = useState(0)

    const slider = React.useRef(null);
    const [filters, setFilters] = useState([]);
    const [currentFilter, setCurrentFilter] = useState('');
    const [models, setModels] = useState([]);

    const openBottomMessage = typeof window !== 'undefined'  && window.openBottomMessage

    useEffect(() => {
        let filtersArr = vehicleslider.map(obj => obj.modelType) // Gets filters
        let newFilter = filtersArr[0] // default value needs to be passed in from metadata (if default is found in the filters array then use that as the default, else use the first filter in the array)

        setFilters(filtersArr)
        setCurrentFilter(newFilter)
    }, [])

    useEffect(() => {
        if (currentFilter !== '') {
            let modelsArr = vehicleslider.find(obj => obj.modelType === currentFilter).models.map(obj => obj)
            setModels(modelsArr)
        }
    }, [currentFilter])

    const handleFilterClick = (e) => {
        let siblings = []
        let sibling = e.currentTarget.parentNode.firstChild

        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== e.currentTarget) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling
        }

        siblings.map(sibling => {
            if (sibling.classList.contains('selected')) {
                sibling.classList.remove('selected')
                e.currentTarget.classList.add('selected')
                setCurrentFilter(e.currentTarget.getAttribute('id'))
            }
        })
    }

    const handleFilterIndex = (index) => {
        const models = vehicleslider[index].models
        setTextIndex(index)
        setModels(models)
    }

    const textSliderSettings = {
        infinite: true,
        slidesToShow: Math.min(filters.length, 4),
        slidesToScroll: 1,
        afterChange: function (index) {
            const models = vehicleslider[index].models
            setTextIndex(index)
            setModels(models)
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: Math.min(filters.length, 2),
                    slidesToScroll: 1
                }
            }
        ]
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: Math.min(models.length, 4),
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(models.length, 3),
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: Math.min(models.length, 2),
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 518,
                settings: {
                    slidesToShow: Math.min(models.length, 1),
                    slidesToScroll: 1
                }
            }
        ]
    }

    return filters.length > 0 && currentFilter !== '' && models.length > 0 ? (
        <div className="vehicle-slider__container">
            <div className="slider-text">
                <h1 className="title">{data.sliderTitle}</h1>
                <p className="sub-title">{data.sliderSubTitle}</p>
            </div>
            <div className="slider-filters">
                <Slider ref={textSlider} {...textSliderSettings}>
                    {filters.map((filter, index) => (
                        <div className="label-wrap">
                            <h3 className={`${filters.length === 1 && "solo-filter selected"} ${index === textIndex ? "filter selected" : "filter"}`}
                                key={`label-${index}`} id={filter} onClick={e => handleFilterIndex(index)}>{filter}</h3>
                        </div>
                    ))}
                </Slider>
                <button className="text-arrows prev-text" onClick={() => textSlider?.current?.slickPrev()}>
                    <i class="arrow left"></i>
                </button>
                <button className="text-arrows next-text" onClick={() => textSlider?.current?.slickNext()}>
                    <i class="arrow right"></i>
                </button>
            </div>
            <div className="slider-container">
                <Slider ref={slider} {...settings}>
                    {models.map((model, index) => {
                        return (
                            <div key={index} className="slide">
                                <h4 className="name">{model.name}</h4>
                                {model.msrp? 
                                    <p className="msrp"><strong>{model.msrp}</strong> STARTING MSRP<span className="disclaimer-star" onClick={e => openBottomMessage("MSRP",model.msrpDisclaimer)}>*</span></p>:
                                    <></>
                                }
                                {model.mpg && <p className="msrp"><strong>{model.mpg}</strong> CITY/HWY MPG RTG<span className="disclaimer-star" onClick={e => openBottomMessage("CITY/HWY MPG RTG",model.mpgDisclaimer)}>*</span></p>}
                                <img className="image" src={model.jellybean} alt="jellybean" />
                                <div className="btns">
                                    {model.leftBtnText ?
                                        <Link className="slide-btn" to={model.leftBtnLink}>{model.leftBtnText}</Link>
                                        :
                                        <></>
                                    }
                                    {model.rightBtnText ?
                                        <Link className="slide-btn" to={model.rightBtnLink}>{model.rightBtnText}</Link>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </Slider>
                <button className="prev" onClick={() => slider?.current?.slickPrev()}><i class="arrow left"></i></button>
                <button className="next" onClick={() => slider?.current?.slickNext()}><i class="arrow right"></i></button>
            </div>
        </div>
    ) : ''
}

export default VehicleSlider
