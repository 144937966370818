import React, { useState, useRef } from "react"
import Slider from 'react-slick' 
import CustomDots from './CustomDots'; 
import './BlogSlider.scss'
import { Link } from "gatsby";


const BlogSlider = (props) => {

    const { data, blogs, blogResultsPageSlug } = props;
    const [slides, setSlides] = useState(blogs || []);
    const slider = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    if (!blogs) {
        console.log("No blogs.")
        return null;
    }

    const blogTitle = data.blogTitle;
    const blogButtonText = data.blogButtonText;

    const next = () => {
        slider.current.slickNext();
    }

    const prev= () => {
        slider.current.slickPrev();
    }

    const goToSlide = (index) => {
        slider.current.slickGoTo(index);
    };

    const afterChange = (index) => {
        setCurrentSlide(index);
    };

    if (slides.length === 0) return <div></div>;

    // function to format publication date to display Month, Day, Year
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        if (isNaN(date.getTime())) {
            // Invalid date format, return input as is
            return inputDate;
        }
    
        const options = { 
            month: 'long', 
            day: 'numeric', 
            year: 'numeric' 
        };
    
        return date.toLocaleDateString(undefined, options);
    }

    let blogCards = slides.map((blog, i) => {

        const pageTitle = JSON.parse(blog.blogData.pageTitle);
        const slug = JSON.parse(blog.blogData.slug);
        const image = blog.blogData.blogposts.thumbnailImage;
        const datePublished = blog.blogData.blogposts.formattedPublicationDate;
        let formattedDate = formatDate(datePublished);

        return (
            <a href={slug} >
                <div key={i} className='bs-card-div'>
                    <img alt={pageTitle != '' ? pageTitle : ''} className='bs-card-image' src={image} />
                        <div class="bs-card-info">
                            <h4>
                                <span>{formattedDate}</span>
                                {pageTitle != '' ? pageTitle : ''}
                            </h4>
                            <p>Read More</p>
                        </div>  
                </div>
            </a>
        )
    })

    const settings = {
        infinite: true,
        arrows: false,
        slidesToShow: Math.min(slides.length, 4),
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        afterChange: afterChange, 
        responsive: [
            {
                breakpoint: 1200,
                settings: { 
                    slidesToShow: Math.min(slides.length, 3),
                    slidesToScroll: Math.min(slides.length, 1),
                }
            },
            {
                breakpoint: 700,
                settings: { 
                    slidesToShow: Math.min(slides.length, 2),
                    slidesToScroll: Math.min(slides.length, 1),
                }
            },
            {
                breakpoint: 500,
                settings: { 
                    slidesToShow: Math.min(slides.length, 1),
                    slidesToScroll: Math.min(slides.length, 1),
                }
            }
        ]
    };    

    return (
        <>
        <div className={`bs-outer-slider ${slides.length === 1 ? 'bs-single-slide' : slides.length === 2 ? 'bs-two-slides' : ''}`}>
            <div className="blog-Cont">
                <div className='blog-slider'>
                    <div className='blog-title'>{blogTitle != '' ? blogTitle : 'The Latest News'}</div>
                    <Link className='bs-cta BSDesktop' to={blogResultsPageSlug}>{blogButtonText != '' ? blogButtonText : 'View All'}</Link>
                </div>
            </div>
            <div className='blog-slider'>
                <Slider ref={slider} {...settings}>
                    {blogCards}
                </Slider>
            </div>
            <div className="blog-slider bs-nav-buttons" style={{ display: slides.length <= 1 ? 'none' : 'flex' }}>
                <div className='blog-button' onClick={prev}>
                    <span className="bs-slider-arrow-icon bs-left"></span>
                </div>
                <CustomDots currentSlide={currentSlide} totalSlides={slides.length} goToSlide={goToSlide} />
                <div className='blog-button' onClick={next}>
                    <span className="bs-slider-arrow-icon bs-right"></span>
                </div>
            </div>
            <Link className='bs-cta BSMobile' to={blogResultsPageSlug}>{blogButtonText != '' ? blogButtonText : 'View All'}</Link>
        </div>
        </>
    )
}


export default BlogSlider;