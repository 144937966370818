import React, { useState, useEffect } from "react";
import view from "./dropdown.module.scss";

const RadioOption = ({ type, values, addFilter, label, sorter, defaultValue }) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  // Ensure that the default value is only applied on the initial render
  useEffect(() => {
    if (defaultValue && !selectedOption) {
      setSelectedOption(defaultValue); // Set the default option on initial render
      addFilter({ value: defaultValue, type }); // Apply the default filter
    }
  }, [defaultValue, selectedOption, addFilter, type]);

  const onSelected = (value) => {
    if (value !== selectedOption) {
      setSelectedOption(value); // Update the selected option
      addFilter({ value, type }); // Call addFilter to update the parent state
    }
  };

  const renderOptions = () => {
    const keys = Object.keys(values);
    const sortedKeys = sorter ? keys.sort(sorter) : keys;

    return sortedKeys.map((key, idx) => (
      <label key={idx} className={view["radio-option"]}>
        <input
          type="radio"
          name={type}
          value={key}
          checked={selectedOption === key} // Ensure the correct radio is checked
          onChange={() => onSelected(key)} // Handle change for the selected radio
        />
        {`${key}`}
      </label>
    ));
  };

  return (
    <div className={view["radio-group"]}>
      <p className={view["radio-group__label"]}>{label && label}</p>
      <div className={view["radio-group__options"]}>
        {renderOptions()}
      </div>
    </div>
  );
};

export default RadioOption;